import styles from './benutzerdaten.module.scss';

import BooleanIcon from '../components/BooleanIcon/BooleanIcon';
import DataTable from '../components/DataTable/DataTable';
import Layout from 'components/layout';
import Wrapper from 'components/Wrapper/Wrapper';
import StickyBar from 'components/StickyBar/StickyBar';
import api from 'api';

import React, { useEffect, useState } from 'react';

import Container from '@wienenergiegithub/ui-next/src/common/components/Container/Container';
import Button from '@wienenergiegithub/ui-next/src/common/components/Button/Button';

import InputField from '@wienenergiegithub/ui-next/src/common/components/InputField/InputField';

import { useNotification } from '@wienenergiegithub/layout/src/common/modules/Notification/Context';
import { navigate } from 'gatsby';

import {
  unstable_useFormState as useFormState,
  unstable_Form as ReakitForm,
  unstable_FormSubmitButton as FormSubmitButton,
} from 'reakit/Form';

import Dropdown from '@wienenergiegithub/ui-next/src/common/components/Dropdown/Dropdown';
import Heading from '@wienenergiegithub/ui-next/src/common/components/Heading/Heading';

const createLaravelFormError = error => {
  const errors = {};
  if (!error?.response?.data) {
    throw errors;
  }
  Object.entries(error.response.data.errors).forEach(([key, val]) => {
    errors[key] = val.join(' ');
  }, {});

  throw errors;
};

const Page = () => {
  const [user, setUser] = useState();
  const [roles, setRoles] = useState();
  const [mostPrivilegedRole, setMostPrivilegedRole] = useState();
  const notifications = useNotification();

  const form = useFormState({
    values: { user: {}, role: {} },
    onValidate: values => {
      const errors = {};
      if (!values.user.name) {
        if (!errors.user) {
          errors.user = [];
        }
        if (!errors.user.name) {
          errors.user.name = {};
        }
        errors.user.name = 'E-Mail muss ausgefüllt werden';
      }
      if (Object.keys(errors).length) {
        throw errors;
      }
    },
    onSubmit: async values => {
      const id = (Math.random() + 1).toString(36).substring(7);
      console.log('submit', values.role.value);
      try {
        const {
          data: { data },
        } = await api({
          url: `/backend/api/users${user?.id ? `/${user.id}` : ''}`,
          method: user?.id ? 'PUT' : 'POST',
          data: {
            ...values.user,
            role: values.role.value,
            email: values.user.name,
          },
        });

        // random id for notification popup
        notifications.add({
          title: 'Kunde gespeichert',
          icon: 'check',
          id,
          type: 'success',
          handleClose: () => {
            // eslint-disable-next-line no-console
            console.log('close');
          },
        });
        setTimeout(() => {
          notifications.remove(id);
        }, 3000);

        if (!user?.id) {
          navigate(`?user=${data.id}`);
        }
        setUser(data);
      } catch (error) {
        notifications.add({
          title: 'Es ist ein Fehler aufgetreten',
          details: 'Bitte versuchen Sie es später erneut',
          icon: 'exclamation',
          id,
          type: 'danger',
        });
        setTimeout(() => {
          notifications.remove(id);
        }, 5000);
        // eslint-disable-next-line no-console
        console.error(error);
        createLaravelFormError(error);
      }
    },
  });

  useEffect(() => {
    const getUserData = async () => {
      try {
        const params = new URLSearchParams(window.location.search);
        const userId = params.get('user');

        if (!userId && !user.id) {
          return;
        }
        const {
          data: { data },
        } = await api.get(`/backend/api/users/${userId || user?.id}`);

        if (data?.roles.length > 0) {
          form.update('role', {
            label: data?.roles[0]?.name,
            value: data?.roles[0]?.name,
            permissions: data?.roles[0]?.permissions,
          });
        }
        form.update('user', data);
        setUser(data);
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error(error);
      }
    };
    const getRoleData = async () => {
      try {
        const {
          data: { data: roleData },
        } = await api.get(`/backend/api/roles/`);

        setRoles(
          roleData.map(itm => ({
            label: itm.name,
            value: itm.name,
            permissions: itm.permissions,
          })),
        );

        // retrieve role with the most permissions
        const biggestRole = roleData.reduce((prev, current) => {
          return prev.permissions.length > current.permissions.length
            ? prev
            : current;
        });
        setMostPrivilegedRole(biggestRole);
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error(error);
      }
    };
    getRoleData();
    getUserData();
  }, [user?.id]);

  return (
    <Wrapper>
      <Layout
        breadcrumbs={[
          {
            to: '/benutzer',
            title: 'Benutzer',
          },
        ]}
        title={user?.name || 'Benutzer anlegen'}
      >
        <ReakitForm {...form} className={styles.formContainer}>
          <StickyBar>
            <div className={styles.actions}>
              <FormSubmitButton as={Button} className={styles.submit} {...form}>
                Speichern
              </FormSubmitButton>
            </div>
          </StickyBar>

          {roles && (
            <Container size="lg" className={styles.contentContainer}>
              <InputField name={['user', 'name']} label="E-mail" {...form} />
              <Dropdown label="Rollen" items={roles} name="role" {...form} />
            </Container>
          )}
          {mostPrivilegedRole && form.values.role.permissions && (
            <Container size="lg" className={styles.contentContainer}>
              <Heading as="h1" size="h5">
                Berechtigungen für die &quot;{form.values.role.label}&quot;
                Rolle
              </Heading>
              <DataTable
                data={mostPrivilegedRole.permissions}
                columns={[
                  {
                    Header: 'Berechtigung',
                    accessor: 'name',
                  },
                  {
                    accessor: form.values.role.label,
                    id: form.values.role.label,
                    Header: form.values.role.label,
                    disableSortBy: true,
                    Cell: ({
                      row: {
                        original: { name },
                      },
                    }) => {
                      return (
                        <BooleanIcon
                          isTrue={form.values.role.permissions.some(
                            e => e.name === name,
                          )}
                        />
                      );
                    },
                  },
                ]}
              />
            </Container>
          )}
        </ReakitForm>
      </Layout>
    </Wrapper>
  );
};

export default Page;
