import styles from 'components/BooleanIcon/BooleanIcon.modules.scss';
import React from 'react';
// import FontAwesomeProIcon from '@wienenergiegithub/ui-next/src/common/components/FontAwesome';
import classNames from 'classnames';

const BooleanIcon = ({ isTrue, className }) => {
  return (
    <span
      className={classNames({
        [styles.greenIcon]: isTrue,
        [styles.redIcon]: !isTrue,
        className,
      })}
    >
      {!isTrue ? 'x' : '✓'}
    </span>
  );
  // TODO: this slows down the rendering
  // return (
  //   <FontAwesomeProIcon
  //     icon={isTrue ? 'check' : 'times'}
  //     type="regular"
  //     className={classNames({
  //       [styles.greenIcon]: isTrue,
  //       [styles.redIcon]: !isTrue,
  //       className,
  //     })}
  //   />
  // );
};

export default BooleanIcon;
